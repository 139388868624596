import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../services/service.index';
import { ToastrService } from 'ngx-toastr';


declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  apiUrl:string;
  casUrl:string;
  urlname:string;
  org:string;
  credential:any = {
    org: '',
    password: '',
    email: ''
  }

  constructor(
    private http: HttpClient,
    private _settings: SettingsService,
    public router: Router,
    private toastr: ToastrService
  ) {
    this.apiUrl = 'https://api.miurabox.com/';
    this.casUrl = 'https://users-api.miurabox.com/';
    //this.apiUrl = 'http://127.0.0.1:8000/';
    //this.casUrl = 'http://127.0.0.1:9000/';
  }

  ngOnInit() {
    init_plugins();
  }

  login(){
    if(!this.credential['email'] || this.credential['email'] === ''){
      this.toastr.error('Ingesa un Usuario válido');
      return
    }
    if(!this.credential['password'] || this.credential['password'] === ''){
      this.toastr.error('Ingresa una contraseña válida');
      return
    }
    this.credential['org'] = 'ancora';
    this.credential['username'] = this.credential['email'];
    this.authenticate().then(response => {
      if(response['token_jwt']){
        this._settings.settings.token = response['token_jwt'];
        this._settings.settings.org_logo = response['org']['logo_mini'];
        this._settings.settings.org_name = response['org']['name'];
        this._settings.settings.org_url = response['org']['urlname'];
        this._settings.settings.active_saam = response['org']['active_saam'];
        this._settings.settings.active_multicotizador = response['org']['active_multicotizador'];

        this._settings.settings.username = response['username'];
        this._settings.settings.first_name = response['first_name'];
        this._settings.settings.last_name = response['last_name'];
        this._settings.settings.username = response['username'];
        
        this._settings.saveSettings();
        this.router.navigate(['/siniestros']);
      }
    }, err => {
      try{
        this.toastr.error(err['error']);
      } catch (err){}
      console.log(err);
    }).catch(error => { 
      try{
        this.toastr.error(error['error']);
      } catch (err){}
      console.log(error);
    });
  };

  authenticate(){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/json',
      }
      let data = {
        username: this.credential.username,
        password: this.credential.password,
        org: this.credential.org
      }
      this.http.post(this.casUrl + 'api-token-auth-saam/', data, {headers : headers})
      .subscribe(data => {
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };

}
