import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/service.index';

declare function init_plugins();

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: []
})
export class ProfileComponent implements OnInit {

  user:any = {
    first_name: '',
    last_name: '',
    second_last_name: '',
    rfc: '',
    phone: '',
    email: ''
  }
  constructor(
    public _settings: SettingsService
  ) {
    this.user.first_name = _settings.settings.first_name;
    this.user.last_name = _settings.settings.last_name;
    this.user.email = _settings.settings.email;
   }

  ngOnInit() {
    init_plugins();
  }

}
