import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: Settings = {
    temaUrl: 'assets/css/colors/defaults.css',
    tema: 'default',
    token: '',
    username: '',
    user: '',
    first_name: '',
    last_name: '',
    second_last_name: '',
    rfc: '',
    gender: '',
    phone: '',
    email: '',
    urlname: '',
    org_address: '',
    org_logo: '',
    org_name: '',
    org_url: '',
    siniestro: '',
    active_saam: false,
    active_multicotizador: false
  }

  constructor(
    @Inject(DOCUMENT) private _document
  ) { 
    this.loadSettings();
  }

  saveSettings(){
    localStorage.setItem('settings', JSON.stringify(this.settings));
  }

  loadSettings(){
    if(localStorage.getItem('settings')){
      this.settings = JSON.parse(localStorage.getItem('settings'));
      // this.aplicarTema(this.settings.tema);
    }else{
      // this.aplicarTema(this.settings.tema);
    }
  }

  borarAjustes(){
    this.settings = {
      temaUrl: 'assets/css/colors/defaults.css',
      tema: 'default',
      token: '',
      username: '',
      user: '',
      first_name: '',
      last_name: '',
      second_last_name: '',
      rfc: '',
      gender: '',
      phone: '',
      email: '',
      urlname: '',
      org_address: '',
      org_logo: '',
      org_name: '',
      org_url: '',
      siniestro: '',
      active_saam: false,
      active_multicotizador: false
    }
    this.saveSettings();
  }

  aplicarTema(tema: string){
    let url = `assets/css/colors/${ tema }.css`;
    this._document.getElementById('tema').setAttribute('href', url);

    this.settings.temaUrl = url;
    this.settings.tema = tema;
    this.saveSettings();
  }
}

interface Settings {
  temaUrl: string,
  tema: string,
  token: string,
  username: string,
  user: string,
  first_name: string,
  last_name: string,
  second_last_name: string,
  rfc: string,
  gender: string,
  phone: string,
  email: string,
  urlname: string,
  org_address: string,
  org_logo: string,
  org_name: string,
  org_url: string,
  siniestro: string
  active_saam:boolean
  active_multicotizador:boolean
}