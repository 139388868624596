import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { retry, map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-rxjs',
  templateUrl: './rxjs.component.html',
  styles: []
})
export class RxjsComponent implements OnInit, OnDestroy {

  subdcription: Subscription;

  constructor() {
    // this.regresaObservable().pipe(
    //   retry(2)
    // ).subscribe(
    //   numero => console.log(numero),
    //   error => console.log(error),
    //   () => console.log('end')
    // );

    this.subdcription = this.regresaObservable().subscribe(
      numero => console.log(numero),
      error => console.log(error),
      () => console.log('end')
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    console.log('bye');
    this.subdcription.unsubscribe();
  }

  regresaObservable(): Observable<any>{
    return new Observable((observer: Subscriber<any>) => {
      let contador = 0;
      let intervalo = setInterval(() => {
        contador += 1;
        let salida = {
          valor: contador
        }
        observer.next(salida);
        // if(contador === 3){
        //   clearInterval(intervalo);
        //   observer.complete();
        // }
        // if(contador === 2){
        //   clearInterval(intervalo);
        //   observer.error('help');
        // }
      }, 1000);
    }).pipe(
      map(res => res.valor),
      filter((valor, index)=>{
        if((valor % 2) === 1){
          return true;
        }else{
          return false;
        }
      })
    );
  };

}
