import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { PagesComponent } from './pages.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PoliciesComponent } from './policies/policies.component';
import { DetailsComponent } from './details/details.component';

import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromisesComponent } from './promises/promises.component';
import { RxjsComponent } from './rxjs/rxjs.component';

import { HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        PagesComponent,
        ProfileComponent,
        DashboardComponent,
        PoliciesComponent,
        DetailsComponent,
        AccountSettingsComponent,
        RxjsComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    providers: [
        PromisesComponent
    ],
})

export class PagesModule { }