import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PromisesComponent } from '../promises/promises.component';
import { SettingsService } from '../../services/service.index';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styles: []
})
export class PoliciesComponent implements OnInit {

  siniesters:any = [];
  siniesters_car:any = [];
  siniesters_medical:any = [];
  siniesters_life:any = [];
  siniester:any = {};
  numberSiniester;
  previous:string =  null;
  next:string =  null;
  loading: any= false;

  constructor(
    public router: Router,
    private _settings: SettingsService,
    public _promise: PromisesComponent
  ) { }

  ngOnInit() {
    this.getSinisters('');
  }
  
  reload(){
    this.getSinisters(this.numberSiniester);
  }
  searchByNumber(number: string){
    this.getSinisters(number);
  }
  getSinisters(number: string){
    this._promise.getSinisters(number).then(response => {
      this.siniesters_car = response['count'];
      this.siniesters = response['results'];
      this.next = response['next'];
      this.previous = response['previous'];
    }).catch(error => {
      if(error['status'] == 403){
        this.logout();
      }
    });
  };

  getLogo(provider: string){
    if(provider == 'Qualitas'){
      return '../../../assets/images/providers/qualitas.png';
    }else if(provider == 'Aba'){
      return '../../../assets/images/providers/chubb.png';
    }else if(provider == 'Zurich'){
      return '../../../assets/images/providers/zurich.png';
    }else if(provider == 'GNP'){
      return '../../../assets/images/providers/gnp.png';
    }else if(provider == 'HDI'){
      return '../../../assets/images/providers/hdi.png';
    }else if(provider == 'Ana'){
      return '../../../assets/images/providers/ana.png';
    }else if(provider == 'Primero'){
      return '../../../assets/images/providers/primero.png';
    }else if(provider == 'Potosi'){
      return '../../../assets/images/providers/potosi.png';
    }else if(provider == 'Ace'){
      return '../../../assets/images/providers/ace.png';
    }else if(provider == 'Afirme'){
      return '../../../assets/images/providers/afirme.png';
    }else if(provider == 'AIG'){
      return '../../../assets/images/providers/Aig.png';
    }else if(provider == 'AXA Seguros'){
      return '../../../assets/images/providers/axa.png';
    }else if(provider == 'Banorte'){
      return '../../../assets/images/providers/banorte.png';
    }else if(provider == 'Bupa'){
      return '../../../assets/images/providers/bupa.png';
    }else if(provider == 'Dentegra'){
      return '../../../assets/images/providers/dentrega.png';
    }else if(provider == 'Mapfre'){
      return '../../../assets/images/providers/mapfre.png';
    }else if(provider == 'Metlife'){
      return '../../../assets/images/providers/metlife.png';
    }else if(provider == 'Multiva'){
      return '../../../assets/images/providers/multiva.png';
    }else if(provider == 'RSA'){
      return '../../../assets/images/providers/rsa.png';
    }else if(provider == 'Sofimex'){
      return '../../../assets/images/providers/sofimex.png';
    }else{
      return '../../../assets/images/providers/seguros.png';
    }
  }

  goToNavigation(item){
    if(item.auto_siniester){
      if(item.auto_siniester[0]){
        if(item.auto_siniester[0].tipo_siniestro == 'Pérdida Parcial' || item.auto_siniester[0].tipo_siniestro == 'Pérdida Total' || item.auto_siniester[0].tipo_siniestro == 'Trámites Administrativos'){
          this._settings.settings.siniestro = item;
          this._settings.saveSettings();
          this.router.navigate(['/siniestro/', item['id']]);        
        }
      }
    }
  };

  next_page(){
    if (this.next){
      this._promise.getUrl(this.next).then(response => {
        this.siniesters = response['results'];
        this.next = response['next'];
        this.previous = response['previous'];
      }); 
    }
  }
  previous_page(){
    if (this.previous){
      this._promise.getUrl(this.previous).then(response => {
        this.siniesters = response['results'];
        this.next = response['next'];
        this.previous = response['previous'];
      }); 
    }
  }
  generarReporte(){
    this.loading = true
    this._promise.makeReport().then(response => {
      let filename = 'Reporte_Siniestros.xlsx';
      let binaryData = [];
      binaryData.push(response);
      this.loading = false
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/octet-stream'}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.loading = false



      // let data:any = response;
      // const blob = new Blob([data], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
    }).catch(error => {
      this.loading = false
      console.log(error);
    });
  }


  logout(){
    this._settings.borarAjustes();
    this.router.navigate(['/login']);
  }

}
