import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  constructor(
    public _settings: SettingsService
  ) { 
    this._settings.loadSettings();

  }

  ngOnInit() {  
  }

  logout(){
    this._settings.borarAjustes();
  }

}
