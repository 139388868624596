import { Component, OnInit } from '@angular/core';
import { SidebarService, SettingsService } from '../../services/service.index';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  constructor(
    public _sidebar: SidebarService,
    public _settings: SettingsService
  ) { 
    this._settings.loadSettings();
  }

  ngOnInit() {
    console.log(this._settings)
  }
  logout(){
    this._settings.borarAjustes();
  }

}
