<div class="row">
    <div class="col-12 col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor">Póliza</h4>
                <hr>
                <small class="text-muted">No. Póliza</small>
                <h6>{{ policy.poliza_number }}</h6>
                <small class="text-muted">Contratante</small>
                <h6>{{ policy.contractor.full_name }}</h6>
                <small class="text-muted">Aseguradora</small>
                <h6 *ngIf="policy.aseguradora">{{ policy.aseguradora.alias }}</h6>
                <h6 *ngIf="!policy.aseguradora">&nbsp;</h6>
                <small class="text-muted">Subramo</small>
                <h6>{{ policy.subramo.subramo_name }}</h6>
                <small class="text-muted">Estatus</small>
                <h6 *ngIf="policy.status === 1">En Trámite</h6>
                <h6 *ngIf="policy.status === 2">OT cancelada</h6>
                <h6 *ngIf="policy.status === 4">Vigente (Precancelada)</h6>
                <h6 *ngIf="policy.status === 10">Por Iniciar</h6>
                <h6 *ngIf="policy.status === 11">Cancelada</h6>
                <h6 *ngIf="policy.status === 12">Renovada</h6>
                <h6 *ngIf="policy.status === 13">Vencida</h6>
                <h6 *ngIf="policy.status === 14">Vigente</h6>
                <h6 *ngIf="policy.status === 15">No Renovada</h6>
                <h6 *ngIf="policy.status === 16">PreAnulada</h6>
                <h6 *ngIf="policy.status === 17">Anulada</h6>
                <small class="text-muted">Vigencia</small>
                <h6>{{ policy.start_of_validity | date: 'dd/MM/yyyy'}} al {{ policy.end_of_validity | date: 'dd/MM/yyyy'}}</h6>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8">
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor">Información del Siniestro</h4>
                <hr>
                <div class="row" *ngIf="policy.subramo.subramo_name === 'Automóviles'">
                    <div class="col-md-6 col-xs-6 b-r">
                        <small class="text-muted">Afectado</small>
                        <h6>{{ policy.contractor.full_name }} - Serie: {{ policy.automobiles_policy[0].serial }}</h6>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">Estatus</small>
                        <h6>{{ siniester.status }}</h6>
                        <!-- <h6 *ngIf="siniester.status === 1">Pendiente</h6>
                        <h6 *ngIf="siniester.status === 2">En Trámite</h6>
                        <h6 *ngIf="siniester.status === 3">Completado</h6>
                        <h6 *ngIf="siniester.status === 4">Cancelado</h6>
                        <h6 *ngIf="siniester.status === 5">Rechazado</h6> -->
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <small class="text-muted">Fecha de Emisión</small>
                        <h6>{{ siniester.fecha_ingreso | date: 'dd/MM/yyyy' }}</h6>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">No. de Siniestro</small>
                        <h6>{{ siniester.numero_siniestro }}</h6>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">Fecha en que Ocurrió</small>
                        <h6>{{ siniester.fecha_siniestro | date: 'dd/MM/yyyy' }}</h6>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">Tipo de Siniestro</small>
                        <h6 >{{autoSiniester.tipo_siniestro}}</h6>                        
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small *ngIf="autoSiniester.subtipo_siniestro_1_name !=null" class="text-muted">Subtipo Siniestro Nivel 1</small>
                        <h6 *ngIf="autoSiniester.subtipo_siniestro_1_name !=null">{{autoSiniester.subtipo_siniestro_1_name}}</h6>  
                        <small *ngIf="autoSiniester.subtipo_siniestro_2_name !=null" class="text-muted">Subtipo Siniestro Nivel 2</small>
                        <h6 *ngIf="autoSiniester.subtipo_siniestro_2_name !=null">{{autoSiniester.subtipo_siniestro_2_name}}</h6>                      
                    </div>
                    <div class="col-md-3 col-xs-6" *ngIf="autoSiniester.tercero">
                        <small class="text-muted">Contra Qué</small>
                        <h6>{{ autoSiniester.tercero }}</h6>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">Como Ocurrió</small>
                        <h6>{{ autoSiniester.explicacion }}</h6>
                    </div>
                    <div class="col-md-3 col-xs-6 b-r">
                        <small class="text-muted">Conductor</small>
                        <h6>{{ autoSiniester.conductor }}</h6>
                    </div>
                </div>
                <div class="row" *ngIf="siniester.subramo == 'Gastos Médicos'">
                    <div class="col-md-12 b-r">
                        <h5>{{ 'certificate.certificate.holder'  }}</h5>
                        <hr>
                    </div>
                    <div class="col-md-4 col-xs-6 b-r">
                        <small class="text-muted">{{ 'certificate.certificate.holder_name'  }}</small>
                        <h6>{{ certificate.accidents_policy[0].personal.first_name }} {{ certificate.accidents_policy[0].personal.last_name }} {{ certificate.accidents_policy[0].personal.second_last_name }}</h6>
                    </div>
                    <div class="col-md-4 col-xs-6 b-r">
                        <small class="text-muted">{{ 'certificate.certificate.holder_birthdate'  }}</small>
                        <h6>{{ certificate.accidents_policy[0].personal.birthdate | date:'dd/MM/yyyy' }}</h6>
                    </div>
                    <div class="col-md-4 col-xs-6 b-r">
                        <small class="text-muted">{{ 'certificate.certificate.holder_antique'  }}</small>
                        <h6>{{ certificate.accidents_policy[0].personal.antiguedad | date:'dd/MM/yyyy' }}</h6>
                    </div>
                    <div class="col-md-12 b-r">
                        <hr>
                        <h5>{{ 'certificate.certificate.dependent'  }}</h5>
                        <hr>
                    </div>
                    <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                        <thead>
                            <tr>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="persist">
                                    <small class="text-muted">{{ 'certificate.certificate.dependent_name'  }}</small>
                                </th>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                    <small class="text-muted">{{ 'certificate.certificate.dependent_birthdate'  }}</small>
                                </th>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">
                                    <small class="text-muted">{{ 'certificate.certificate.dependent_relationship'  }}</small>
                                </th>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="3">
                                    <small class="text-muted">{{ 'certificate.certificate.dependent_antique'  }}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of certificate.accidents_policy[0].relationship_accident">
                                <td class="title"><h6>{{ item.first_name }} {{ item.last_name }} {{ item.second_last_name }}</h6></td>
                                <td><h6>{{ item.birthdate | date:'dd/MM/yyyy' }}</h6></td>
                                <td>
                                    <h6 *ngIf="item.relationship == 1">Titular</h6>
                                    <h6 *ngIf="item.relationship == 2">Cónyuge</h6>
                                    <h6 *ngIf="item.relationship == 3">Hijo</h6>
                                    <h6 *ngIf="item.relationship == 4">Otro</h6>
                                </td>
                                <td><h6>{{ item.antiguedad | date:'dd/MM/yyyy' }}</h6></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor">Información de Contacto</h4>
                <hr>
                <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                    <thead>
                        <tr>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="persist">
                                <small class="text-muted">Nombre</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                <small class="text-muted">Correo</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">
                                <small class="text-muted">Teléfono</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="3">
                                <small class="text-muted">Referencia</small>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contact of autoSiniester.contacto_auto">
                            <td class="title"><h6>{{ contact.first_name }}</h6></td>
                            <td class="title"><h6>{{ contact.email }}</h6></td>
                            <td class="title"><h6>{{ contact.phone }}</h6></td>
                            <td class="title"><h6>{{ contact.references }}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card" *ngIf="form.controls['subtipo_nivel_2'].value == 1">
            <div class="card-body">
                <h4 class="text-themecolor">Archivos Cargados</h4>
                <hr>
                <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                    <thead>
                        <tr>
                            <!-- <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="persist">
                                <small class="text-muted">Validar</small>
                            </th> -->
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="persist">
                                <small class="text-muted">Nombre</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                <small class="text-muted">Acciones</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="persist">
                                <small class="text-muted">Entregado y Validado</small>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of formFiles; let i = index">
                            <!-- <td>
                                <div >
                                    <input [id]="file.id" (change)="validateFile(file)" type="checkbox" class="custom-control-input" [(ngModel)]="file.checked">
                                    <label [for]="file.id" class="custom-control-label"></label>
                                </div>
                            </td> -->
                            <td class="title"><h6>{{ file.nombre }}</h6></td>
                            <td>
                                <a class="btn btn-success btn-outline-success" [href]="file.arch" target="_black" *ngIf="file.arch">Descargar</a>
                                <div class="col-12 col-sm-12 form-group" *ngIf="file.arch == null || !file.arch">
                                    <input type="file" [name]="file.nombre" [id]="file.nombre" style=" width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1;"  [disabled]="siniester.status =='Completada'"
                                    (change)="onFileChange(file, $event, i)" />
                                    <label for="{{file}}" class="col-xs-12 btn btn-primary btn-outline-primary waves-effect waves-light m-t-10">Cargar Archivo</label>
                                    <br>
                                    <small class="text-muted">{{ event[i] }}</small>
                                </div>
                            </td>
                            <td>
                                <div >
                                    <input [id]="file.id" (change)="deliveryFile(file)" type="checkbox" class="custom-control-input" [(ngModel)]="file.delivery">
                                    <label [for]="file.id" class="custom-control-label"></label>
                                    <span *ngIf="file.delivery == true"> Entregado</span>
                                    <span *ngIf="file.delivery == false"> No Entregado</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <button *ngIf="archivos && archivos.length == 0" class="btn btn-success pull-right" style="margin-left: 10px;" [disabled]="siniester.status =='Completada'" (click)="validateFiles()">Validar todos los archivos</button> -->                
            </div>
            <div class="card-body" *ngIf="listafiles.length>0">
                <!-- nueva tabla subir files no arch -->
                <form  #otherForm="ngForm" [formGroup]="formExistente" (ngSubmit)="saveUpdFiles()">
                    <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                        <thead>
                          <tr>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                <small class="text-muted">Nombre</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">
                                <small class="text-muted">Cargar Archivo</small>
                            </th>
                            <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">Entregado</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let filetoupd of listafiles; let ix = index"> 
                            <td>
                                <b>{{filetoupd.nombre}}</b>
                            </td>
                            <td>
                                <div class="col-12 col-sm-12 form-group">
                                    <input type="file" [name]="filetoupd.nombre" [id]="filetoupd.nombre" style=" width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1;"  [disabled]="siniester.status =='Completada'"
                                    (change)="onFileChangeUpd(filetoupd.nombre, $event, ix,filetoupd)" />
                                    <label for="{{filetoupd.nombre}}" class="col-xs-12 btn btn-primary btn-outline-primary waves-effect waves-light m-t-10">Cargar Archivo</label>
                                    <br>
                                    <small class="text-muted">{{ eventx[ix]}}</small>
                                </div>
                            </td>
                            <td>
                                <div >
                                    <input [id]="filetoupd.id" (change)="onFileChangeDelivery(ix,$event,filetoupd,$event.target)" type="checkbox" class="custom-control-input" *ngIf="filetoupd.delivery == true" checked>
                                    <input [id]="filetoupd.id" (change)="onFileChangeDelivery(ix,$event,filetoupd,$event.target)" type="checkbox" class="custom-control-input" *ngIf="filetoupd.delivery == false" >
                                    <label [for]="filetoupd.id" class="custom-control-label"></label>
                                    <span *ngIf="filetoupd.delivery == true"> Entregado</span>
                                    <span *ngIf="filetoupd.delivery == false"> No Entregado</span>
                                </div>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    <div class="col-12 form-group">
                        <button class="btn btn-info btn-outline-info float-right" type="submit"  [disabled]="siniester.status == 'Completada' || siniester.status == 'Rechazada' || siniester.status == 'Cancelada'">Subir Archivos</button>
                    </div>
                </form>
                <!-- fin nueva forma subir archivos -->
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor">Bitácora</h4>
                <hr>
                <!-- <div class="col-xs-12">
                    <h6 style="white-space: pre;">{{siniester.bitacora}}</h6>
                </div> -->
                <hr>
                <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                    <thead>
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let bit of siniester.bitacora2; let i = index">
                            <td>
                                <div *ngIf="bit.content !=null">Comentario #{{i+1}}: <br>{{bit.content}} <br><span class="pull-right">{{bit.created_at | date: 'dd/MM/yyyy - HH:mm:ss'}} {{bit.user_info}}</span>
                                    <label [for]="bit.id" class="custom-control-label"></label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <div class="ibox-content col-xs-12">
                    <h4 class="text-themecolor">Añadir Bitácora</h4>
                    <hr>
                    <div class="col-xs-12">
                        <input class="form-control" type="text" [(ngModel)]="comment" placeholder="Escriba un comentario...">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 form-group">
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor" *ngIf="autoSiniester.subtipo_siniestro_1_name != 'Legal'">Semáforo</h4>
                <hr>
                <button *ngIf="siniester.status != 'Completada' || siniester.status != 'Rechazada' || siniester.status != 'Cancelada' && autoSiniester.subtipo_siniestro_1" class="btn btn-success pull-right" style="margin-left: 10px;" [disabled]="siniester.status =='Completada' || autoSiniester.subtipo_siniestro_1_name =='Reparación'" (click)="validateFilesv2()">Iniciar Semáforo</button>
                <div *ngIf="siniester.status != 'Completada' && siniester.status != 'Rechazada' && siniester.status != 'Cancelada'">
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Reparación'">
                        <!-- <div *ngIf="siniester.antiguedad_horas_habiles <= -72" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_horas_habiles > -72 && siniester.antiguedad_dias_habiles < 0" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles > 0 && siniester.antiguedad_dias_habiles > 0" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles_real == 0 && siniester.antiguedad_dias_habiles ==0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>  -->
                        <div *ngIf="siniester.antiguedad_dias_promesa < -2" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_promesa >= -2 && siniester.antiguedad_dias_promesa < 0" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_promesa >= 0" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        <div *ngIf="siniester.promise_date == null && siniester.antiguedad_dias_promesa == 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Valuación'">
                        <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_dias_habiles <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_dias_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles > 48 && siniester.antiguedad_dias_habiles <= 72" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles > 72" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && (autoSiniester.subtipo_siniestro_1_name == 'Conclusión de ajuste o peticiones en general' || autoSiniester.subtipo_siniestro_1_name == 'Legal')">
                        <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_dias_habiles <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_dias_habiles <= 12" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles > 12 && siniester.antiguedad_dias_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.antiguedad_dias_habiles > 24" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Total'">
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '1'">
                            <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_dias_habiles <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real >0 && siniester.antiguedad_dias_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles > 24 && siniester.antiguedad_dias_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles > 48" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '2'">
                            <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_horas_habiles <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_horas_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 48 && siniester.antiguedad_horas_habiles <= 72" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 72 && siniester.antiguedad_horas_habiles <= 120" style="display: block; height: 60px; width: 60px; background-color: #FF9100; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 120" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="!autoSiniester.subtipo_siniestro_2">
                            <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_horas_habiles <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_dias_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #E0E0E0; border-radius: 50%;"></div>
                        </div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Trámites Administrativos'">
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '1'">
                            <div *ngIf="autoSiniester.subtipo_siniestro_1 != '12'">
                                <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_dias_habiles <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div><!-- ok-->
                                <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_dias_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                                <div *ngIf="siniester.antiguedad_dias_habiles > 24 && siniester.antiguedad_dias_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                                <div *ngIf="siniester.antiguedad_dias_habiles > 48" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>                            
                            </div>
                            <div *ngIf="autoSiniester.subtipo_siniestro_1 == '12'">
                                <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_dias_habiles <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div><!-- ok-->
                                <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_dias_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                                <div *ngIf="siniester.antiguedad_dias_habiles > 24 && siniester.antiguedad_dias_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                                <div *ngIf="siniester.antiguedad_dias_habiles > 48 && siniester.antiguedad_dias_habiles <= 72" style="display: block; height: 60px; width: 60px; background-color: #FF9100; border-radius: 50%;"></div>
                                <div *ngIf="siniester.antiguedad_dias_habiles > 72" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>                            
                            </div>
                        </div>
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '2'"><!--indemnización-->
                            <!-- <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_horas_habiles <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;">1</div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_horas_habiles <= 48" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;">2</div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 48 && siniester.antiguedad_horas_habiles <= 72" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;">3</div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 72 && siniester.antiguedad_horas_habiles <= 120" style="display: block; height: 60px; width: 60px; background-color: #FF9100; border-radius: 50%;">4</div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 120" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;">5</div> -->                         
                            <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_horas_habiles <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_horas_habiles < 72" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_horas_habiles >= 72 && siniester.antiguedad_horas_habiles <= 120" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_horas_habiles > 120" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="!autoSiniester.subtipo_siniestro_2">
                            <div *ngIf="siniester.antiguedad_dias_habiles_real <= 0 && siniester.antiguedad_horas_habiles <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.antiguedad_dias_habiles_real > 0 && siniester.antiguedad_horas_habiles <= 24" style="display: block; height: 60px; width: 60px; background-color: #E0E0E0; border-radius: 50%;"></div>
                        </div>
                    </div>                    
                </div>
                <!-- --------------------------------- -->                
                <div *ngIf="siniester.status == 'Completada' || siniester.status == 'Rechazada' || siniester.status == 'Cancelada'">
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Reparación'">
                        <div *ngIf="siniester.dias_horas_final_promesa < -2" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final_promesa >= -2 && siniester.dias_horas_final_promesa < 0" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final_promesa >= 0" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        <div *ngIf="siniester.promise_date == null" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Valuación'">
                        <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 48" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 48 && siniester.dias_horas_final <= 72" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 72" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && (autoSiniester.subtipo_siniestro_1_name == 'Conclusión de ajuste o peticiones en general' || autoSiniester.subtipo_siniestro_1_name == 'Legal')">
                        <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 12" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 12 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                        <div *ngIf="siniester.dias_horas_final > 24" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Total'">
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '1'">
                            <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final >0 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 24 && siniester.dias_horas_final <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 48" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '2'">
                            <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 48" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 48 && siniester.dias_horas_final <= 72" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 72 && siniester.dias_horas_final <= 120" style="display: block; height: 60px; width: 60px; background-color: #FF9100; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 120" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="!autoSiniester.subtipo_siniestro_2">
                            <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <=0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #E0E0E0; border-radius: 50%;"></div>
                        </div>
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro == 'Trámites Administrativos'">
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '1'">
                            <div *ngIf="autoSiniester.subtipo_siniestro_1 != '12'">
                                <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div><!-- ok-->
                                <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                                <div *ngIf="siniester.dias_horas_final > 24 && siniester.dias_horas_final <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                                <div *ngIf="siniester.dias_horas_final > 48" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>                            
                            </div>
                            <div *ngIf="autoSiniester.subtipo_siniestro_1 == '12'">
                                <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div><!-- ok-->
                                <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                                <div *ngIf="siniester.dias_horas_final > 24 && siniester.dias_horas_final <= 48" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                                <div *ngIf="siniester.dias_horas_final > 48 && siniester.dias_horas_final <= 72" style="display: block; height: 60px; width: 60px; background-color: #FF9100; border-radius: 50%;"></div>
                                <div *ngIf="siniester.dias_horas_final > 72" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>                            
                            </div>
                        </div>
                        <div *ngIf="autoSiniester.subtipo_siniestro_2 == '2'">                       
                            <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final < 3" style="display: block; height: 60px; width: 60px; background-color: #00E676; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final >= 3 && siniester.dias_horas_final <= 5" style="display: block; height: 60px; width: 60px; background-color: #FFEA00; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 5" style="display: block; height: 60px; width: 60px; background-color: #FF0000; border-radius: 50%;"></div>
                        </div>
                        <div *ngIf="!autoSiniester.subtipo_siniestro_2">
                            <div *ngIf="siniester.dias_horas_final <= 0 && siniester.dias_horas_final <= 0" style="display: block; height: 60px; width: 60px; background-color: #808080; border-radius: 50%;"></div>
                            <div *ngIf="siniester.dias_horas_final > 0 && siniester.dias_horas_final <= 24" style="display: block; height: 60px; width: 60px; background-color: #E0E0E0; border-radius: 50%;"></div>
                        </div>
                    </div>                    
                </div>
                <!-- ************************************* -->
                <div style="display: block; margin: -50px 0 0 80px;">
                    <div *ngIf="(autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Reparación') || autoSiniester.subtipo_siniestro_2 ==2">
                        <small class="text-muted">Días hábiles</small>   
                    </div>
                    <div *ngIf="(autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Conclusión de ajuste o peticiones en general') || (autoSiniester.tipo_siniestro == 'Pérdida Parcial') || (autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Valuación') || (autoSiniester.tipo_siniestro == 'Pérdida Total' && autoSiniester.subtipo_siniestro_1_name == 'Daño Material') || autoSiniester.subtipo_siniestro_2 ==1">
                        <small class="text-muted" *ngIf="autoSiniester.subtipo_siniestro_1_name !='Reparación'">Horas hábiles</small>
                    </div>
                    
                    <!-- <h6>{{ siniester.antiguedad_dias_habiles }} dias</h6> -->
                    <div *ngIf="siniester.status != 'Completada' && siniester.status != 'Rechazada' && siniester.status != 'Cancelada'">
                        <div *ngIf="autoSiniester.tipo_siniestro != 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name != 'Reparación'">
                            <h6 *ngIf="siniester.antiguedad_dias_habiles_real !=0 || siniester.antiguedad_dias_habiles_real !='0'">{{ siniester.antiguedad_dias_habiles }} </h6>
                            <h6 *ngIf="siniester.antiguedad_dias_habiles_real ==0 || siniester.antiguedad_dias_habiles_real =='0'">Por iniciar </h6>
                        </div>
                       <!--  <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Reparación'">
                            <h6 *ngIf="siniester.promise_date != null">{{ siniester.antiguedad_dias_promesa }} </h6> -->
                        <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name == 'Reparación'">
                            <h6 *ngIf="siniester.promise_date != null">{{ siniester.antiguedad_dias_promesa }}</h6>
                            <h6 *ngIf="siniester.promise_date == null">Por iniciar </h6>
                        </div>
                        <div *ngIf="autoSiniester.tipo_siniestro == 'Pérdida Parcial' && autoSiniester.subtipo_siniestro_1_name != 'Reparación'">
                            <h6>{{ siniester.antiguedad_dias_habiles }} </h6>
                        </div>                        
                    </div>
                    
                    <div *ngIf="siniester.status == 'Completada' || siniester.status == 'Rechazada' || siniester.status == 'Cancelada'">
                        <h6 *ngIf="autoSiniester.subtipo_siniestro_1_name != 'Reparación' && (autoSiniester.subtipo_siniestro_1 !=null || autoSiniester.subtipo_siniestro_2 !=null)">{{ siniester.dias_horas_final }} </h6>

                        <h6 *ngIf="autoSiniester.subtipo_siniestro_1_name == 'Reparación' && (autoSiniester.subtipo_siniestro_1 !=null || autoSiniester.subtipo_siniestro_2 !=null)">{{siniester.dias_horas_final_promesa}}</h6>
                        <h6 *ngIf="autoSiniester.subtipo_siniestro_1 ==null && autoSiniester.subtipo_siniestro_2 ==null">Por iniciar</h6>
                        <h6 *ngIf="autoSiniester.subtipo_siniestro_1 !=null || autoSiniester.subtipo_siniestro_2 !=null">Detenido</h6>
                    </div>
                </div>
                <br>
                <div class="row col-xs-12"><br>
                    <div class="col-xs-12 form-group" *ngIf="autoSiniester.subtipo_siniestro_1_name != 'Reparación'">
                        <div class="col-xs-6" *ngIf="siniester.fecha_archivos_cargados !=null">
                            <small class="text-muted">Fecha de Inicio de Trámite</small>
                            <h6 ><span *ngIf="siniester.fecha_archivos_cargados !=null ">{{siniester.fecha_archivos_cargados | date: 'dd/MM/yyyy hh:mm:ss a'}} (CST)</span></h6>     
                            <!-- <h6 ><span *ngIf="siniester.promise_date !=null">{{siniester.promise_date | date: 'dd/MM/yyyy hh:mm:ss a'  : 'UTC'}} (CST)</span></h6>                     -->
                        </div>                       
                    </div>
                </div>
                <div class="row" *ngIf="autoSiniester.subtipo_siniestro_1_name == 'Reparación'">                    
                    <div class="col-xs-6 form-group" *ngIf="siniester.promise_date !=null || siniester.fecha_compromiso !=null" >
                        <small class="text-muted">Fecha Promesa</small>
                        <h6 ><span *ngIf="siniester.promise_date ==null && siniester.fecha_compromiso !=null">{{siniester.fecha_compromiso | date: 'dd/MM/yyyy hh:mm:ss a'}} (CST)</span></h6>
                        <h6 ><span *ngIf="siniester.promise_date !=null">{{siniester.promise_date | date: 'dd/MM/yyyy hh:mm:ss a'}} (CST)</span></h6>
                    </div>  
                    <!-- <div class="col-xs-6" *ngIf="siniester.promise_date ==null && siniester.fecha_compromiso ==null">
                        <small class="text-muted">Sin Fecha compromiso</small>
                    </div>    -->              
                </div>
                <div class="row col-xs-12"><br>
                    <div class="col-xs-12 form-group">                        
                        <div *ngIf="siniester.status == 'Completada' || siniester.status == 'Rechazada' || siniester.status == 'Cancelada'">
                            <small class="text-muted">Fecha de Fin Trámite</small>
                            <h6 *ngIf="siniester.fecha_termino_conteo">{{siniester.fecha_termino_conteo | date: 'dd/MM/yyyy hh:mm:ss a'}} (CST)</h6>
                            <h6 *ngIf="!siniester.fecha_termino_conteo">No Registrada</h6>
                        </div>                       
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="text-themecolor">Configuración de Siniestro</h4>
                <hr>
                <div class="col-6 col-sm-6 form-group">
                    <label class="texto-cotizador">Tipo</label>
                    <select class="form-control" [(ngModel)]="autoSiniester.tipo_siniestro" disabled>
                        <option *ngFor="let item of types" [value]="item.name">
                            {{item.name}}
                        </option>
                    </select>
                </div>
                <form class="container form-material" #myForm="ngForm" [formGroup]="form" (ngSubmit)="save()">
                    <div class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador">Subtipo nivel 1</label>
                        <select class="form-control" [class.is-invalid]="v1NoValido" formControlName="subtipo_nivel_1" (change)="loadFiles(form.controls['subtipo_nivel_1'].value)">
                            <option *ngFor="let item of subtypes" [value]="item.id">
                                {{item.name}}
                            </option>
                        </select>
                        <small *ngIf="v1NoValido" class="form-control-feedback text-danger">Seleccione una opción</small> 
                    </div>
                    <div *ngIf="autoSiniester.tipo_siniestro != 'Pérdida Parcial'" class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador">Subtipo nivel 2</label>
                        <select class="form-control" [class.is-invalid]="v2NoValido" formControlName="subtipo_nivel_2">
                            <option *ngFor="let item of subtypesv2" [value]="item.id">
                                {{item.name}}
                            </option>
                        </select>
                        <small *ngIf="v2NoValido" class="form-control-feedback text-danger">Seleccione una opción</small> 
                    </div>
                    <div class="col-8 form-group" *ngIf="form.controls['subtipo_nivel_2'].value == 1 && (archivos && archivos.length >0)">
                        <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                            <thead>
                              <tr>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                    <small class="text-muted">Nombre</small>
                                </th>
                                <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">
                                    <small class="text-muted">Cargar Archivo</small>
                                </th>
                                <!-- <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">Entregado</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let archivo_name of archivos; let i = index" [formGroupName]="archivo_name"> 
                                <td>
                                    <b>{{ archivo_name }}</b>
                                </td>
                                <td>
                                    <div class="col-12 col-sm-12 form-group">
                                        <input type="file" [name]="archivo_name" [id]="archivo_name" style=" width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1;"  [disabled]="siniester.status =='Completada'"
                                        (change)="onFileChange(archivo_name, $event, i)" />
                                        <label for="{{archivo_name}}" class="col-xs-12 btn btn-primary btn-outline-primary waves-effect waves-light m-t-10">Cargar Archivo</label>
                                        <br>
                                        <small class="text-muted">{{ event[i] }}</small>
                                    </div>
                                </td>
                                <!-- <td>
                                    <div >
                                        <input [id]="i" (change)="onFileChangeDelivery(i,$event,archivo_name)" type="checkbox" class="custom-control-input" >
                                        <label [for]="i" class="custom-control-label"></label>
                                    </div>
                                </td> -->
                              </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- lista checks -->
          <!--           <div classv="col-12 form-group" *ngIf="form.controls['subtipo_nivel_2'].value == 1">
                        <div class="col-6 form-group" *ngIf="form.controls['subtipo_nivel_2'].value == 1">
                            <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                                <thead>
                                  <tr>
                                    <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                        <small class="text-muted">Nombre</small>
                                    </th>
                                    <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">Entregado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let filenamecheck of formFiles; let ix = index" [formGroupName]="filenamecheck"> 
                                    <td>
                                        <b>{{ filenamecheck }}</b>
                                    </td>
                                    <td>
                                        <div >
                                            <input [id]="ix" (change)="onFileChangeDelivery(ix,$event,filenamecheck)" type="checkbox" class="custom-control-input" >
                                            <label [for]="ix" class="custom-control-label"></label>
                                        </div>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                        <div classv="col-6 form-group" *ngIf="form.controls['subtipo_nivel_2'].value == 1 && (form.controls['subtipo_nivel_1'].value ==autoSiniester.subtipo_siniestro_1)">
                            <table class="tablesaw table-bordered table-hover table" data-tablesaw-mode="swipe">
                                <thead>
                                  <tr>
                                    <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="1">
                                        <small class="text-muted">Archivo</small>
                                    </th>
                                    <th scope="col" data-tablesaw-sortable-col data-tablesaw-priority="2">Entregado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let filechecked of formFilesSaved; let isaved = index" [formGroupName]="filechecked.file"> 
                                    <td>
                                        <b>{{ filechecked.file}}</b>
                                    </td>
                                    <td>
                                        <span *ngIf="filechecked.delivery =='true' || filechecked.delivery">Entregado</span>
                                        <span *ngIf="filechecked.delivery =='false' || !filechecked.delivery">No Entregado</span>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                    <!-- lista check 2 -->
                    <!--  -->
                    <div class="card" *ngIf="siniester.status != 'Completada' || siniester.status != 'Rechazada' || siniester.status != 'Cancelada'">
                        <div class="card-body">
                            <h4 class="text-themecolor">Cambio de Estado</h4>
                            <hr>
                            <div class="col-12 col-sm-6 form-group">
                                <label class="texto-cotizador">Estado</label>
                                <select class="form-control tablesaw table-bordered table-hover" [class.is-invalid]="sttsNoValido" formControlName="status_siniester" (change)="statusChange(form.controls['status_siniester'].value)">
                                    <option *ngFor="let item of status" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--deshabilitado -->
                    <div class="col-12 form-group">
                        <button class="btn btn-info btn-outline-info float-right" type="submit"  [disabled]="siniester.status =='Completada' || siniester.status =='Cancelada' || siniester.status =='Rechazada'">Guardar</button>
                    </div>
                </form>
                <div *ngIf="showDate" class="col-12 col-sm-6 form-group">
                    <label class="texto-cotizador">Fecha promesa</label>
                    <input class="form-control" type="date" [(ngModel)]="fecha_siniestro">
                </div>
                <div *ngIf="form.controls['status_siniester'].value== '3'">
                    <div class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador">Indemnización</label>
                        <input class="form-control" type="number" [(ngModel)]="indemnizacion">
                    </div>
                    <div class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador">Deducible</label>
                        <input class="form-control" type="number" [(ngModel)]="deducible">
                    </div>
                    <div class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador">Coaseguro</label>
                        <input class="form-control" type="number" [(ngModel)]="coaseguro">
                    </div>                    
                </div>
                <div *ngIf="form.controls['status_siniester'].value== '5' || form.controls['status_siniester'].value== '4'">
                    <div class="col-12 col-sm-6 form-group">
                        <label class="texto-cotizador" *ngIf="form.controls['status_siniester'].value== '4'">Razón de Cancelación</label>
                        <label class="texto-cotizador" *ngIf="form.controls['status_siniester'].value== '5'">Razón de Rechazo</label>
                        <input class="form-control" type="text" [(ngModel)]="reason">
                    </div>                 
                </div>
            </div>
        </div>
    </div>
</div>