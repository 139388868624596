<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="../../../assets/images/icon-saam.png" alt="homepage" class="dark-logo" style="margin: 0 10px;" /> -->
                    <img src="../../../assets/images/ancora_logo.jpg" alt="homepage" class="dark-logo" style="margin: 0 10px;width: 150px;" />                    
                    <!-- Light Logo icon -->
                    <!-- <img src="../../../assets/images/icon-saam.png" alt="homepage" class="light-logo" style="margin: 0 10px;"/> -->
                    <!-- <img src="../../../assets/images/ancora_logo.jpg" alt="homepage" class="light-logo" style="margin: 0 10px;" /> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <!-- <img src="../../../assets/images/text-saam.png" alt="homepage" class="dark-logo" /> -->
                    <!-- <img src="../../../assets/images/ancora_logo.jpg" alt="homepage" class="dark-logo" style="margin: 0 10px;" /> -->
                    <!-- Light Logo text -->    
                    <!-- <img src="../../../assets/images/text-saam.png" class="light-logo" alt="homepage" /></span> </a> -->
                    <img src="../../../assets/images/ancora_logo.jpg" class="light-logo" alt="homepage" style="width: 150px;" /></span> </a>
                    <!-- <img src="../../../assets/images/ancora_logo.jpg" class="light-logo" alt="homepage" /></span> -->
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="mdi mdi-email"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                    <div class="dropdown-menu mailbox dropdown-menu-right animated bounceInDown" aria-labelledby="2">
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/1.jpg" alt="user" class="img-circle"> <span class="profile-status online pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Pavan kumar</h5> <span class="mail-desc">Just see the my admin!</span> <span class="time">9:30 AM</span> </div>
                                    </a>
                                    
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/2.jpg" alt="user" class="img-circle"> <span class="profile-status busy pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Sonu Nigam</h5> <span class="mail-desc">I've sung a song! See you at</span> <span class="time">9:10 AM</span> </div>
                                    </a>
                                   
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/3.jpg" alt="user" class="img-circle"> <span class="profile-status away pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Arijit Sinh</h5> <span class="mail-desc">I am a singer!</span> <span class="time">9:08 AM</span> </div>
                                    </a>
                                    
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/4.jpg" alt="user" class="img-circle"> <span class="profile-status offline pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Pavan kumar</h5> <span class="mail-desc">Just see the my admin!</span> <span class="time">9:02 AM</span> </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);"> <strong>See all e-Mails</strong> <i class="fa fa-angle-right"></i> </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Language -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <!-- <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span *ngIf="_settings.settings.language == 'es'"><i class="flag-icon flag-icon-mx"></i></span>
                        <span *ngIf="_settings.settings.language == 'en'"><i class="flag-icon flag-icon-us"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated bounceInDown">
                        <a *ngIf="_settings.settings.language == 'es'" class="dropdown-item" href="#" (click)="changeLanguage('en')">
                            <i class="flag-icon flag-icon-us"></i>
                            English
                        </a> 
                        <a *ngIf="_settings.settings.language == 'en'" class="dropdown-item" href="#" (click)="changeLanguage('es')">
                            <i class="flag-icon flag-icon-mx"></i>
                            Español
                        </a> 
                    </div> -->
                </li>
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <!-- <img src="../assets/images/users/1.jpg" alt="user" class="profile-pic" /> -->
                        <i class=" mdi mdi-account-circle">{{ _settings.settings.first_name }} {{ _settings.settings.last_name }}</i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <ul class="dropdown-user">
                            <li><a routerLink="/login" (click)="logout()"><i class="fa fa-power-off"></i> Cerrar sesión</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->