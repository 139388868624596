import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../services/service.index';

@Component({
  selector: 'app-promises',
  templateUrl: './promises.component.html',
  styles: []
})
export class PromisesComponent implements OnInit {

  apiUrl:string;
  casUrl:string;
  mcUrl:string;
  urlname:string;
  org:string;
  numberSiniester;
  constructor(
    private http: HttpClient,
    private _settings: SettingsService
  ) {
    this.apiUrl = 'https://api.miurabox.com/';
    this.casUrl = 'https://users-api.miurabox.com/';
    this.mcUrl = 'https://ancora.miurabox.com/';
    this.urlname = 'ancora';
    this.org = 'ancora';
    // this.apiUrl = 'http://127.0.0.1:8000/';
    // this.casUrl = 'http://127.0.0.1:9000/';
    // this.mcUrl = 'http://127.0.0.1:8001/';
    // this.urlname = 'ancora';
    // this.org = 'ancora';
  }

  ngOnInit() {
    this._settings.loadSettings();
  }

  getSinisters(number: string){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      if (number) {
        this.numberSiniester = number;
      }else{
        this.numberSiniester = ''
      }
      this.http.get(this.apiUrl + 'portal-siniestros/' + '?org=' + this.org+'&number='+this.numberSiniester, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getUrl(url:string){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(url, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

      
      
  makeReport(){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      // let headers = {
      //   'Authorization': 'Bearer ' + token,
      //   responseType: 'arraybuffer',
      //   'Content-Type': 'application/json'
      // }
      this.http.get(this.apiUrl+'service-report-portal-siniestros-excel?org=' + this.org+'&number='+this.numberSiniester,
        {
          headers: 
          { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+ token,
          }, 
          responseType: 'blob'
        })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getSinister(id){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(this.apiUrl + 'portal-siniestros/' + id + '/?org=' + this.org, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  getPolicy(url){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(url + '?org=' + this.org, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  getProvider(url){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(url + '?org=' + this.org, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  getContrator(url){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(url + '?org=' + this.org, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  uploadFiles(id){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Authorization': 'Bearer ' + token}
      this.http.get(this.apiUrl + 'siniestros/' + id + '/archivos?org=' + this.org, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  uploadFile(id, payload){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'mimeType': 'multipart/form-data',
      }
      this.http.post(this.apiUrl + 'siniestros/' + id + '/archivos/?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  uploadFileOnlyNull(id, payload){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.post(this.apiUrl + 'siniestros/' + id + '/archivos/?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  uploadFileUPD(url:string, payload){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'mimeType': 'multipart/form-data',
      }
      this.http.patch(url+'?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  updateSiniester(url:string, payload:any){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.patch(url + '?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  sendLog(payload:any){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.post(this.apiUrl+'send-log/?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  updateMainSiniester(id:string, payload:any){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.patch(this.apiUrl + 'portal-siniestros/'+ id + '/?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  patchUrl(url:string, payload:any){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.patch(url + '?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  createComment(payload:any){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
      this.http.post(this.apiUrl+'comments/?org=' + this.org, payload, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

}