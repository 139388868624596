import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../services/service.index';
import { PromisesComponent } from '../promises/promises.component';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styles: []
})
export class DetailsComponent implements OnInit {
  listaFilesDelivery: any = [];
  siniester: any = {
    auto_siniester: []
  };
  policy: any = {
    poliza_number: '',
    contractor: '',
    aseguradora: '',
    subramo: ''
  };
  types: any = [
    {name: 'Selecciona un tipo', value: '0'},
    {name: 'Pérdida Parcial', value: 20},
    {name: 'Pérdida Total', value: 10},
    {name: 'Trámites Administrativos', value: 19}
  ];
  autoSiniester: any = {};
  archivos:any = [];
  event:any = [];
  eventx:any = [];
  comment;
  formFiles: any=[];
  formFilesSaved: any=[];
  listafiles: any = []
  subtypes: any = [
    {
      id: 1,
      name: 'Conclusión de ajuste o peticiones en general',
      tipo: 'Pérdida Parcial'
    }, {
      id: 2,
      name: 'Legal',
      tipo: 'Pérdida Parcial'
    }, {
      id: 3,
      name: 'Valuación',
      tipo: 'Pérdida Parcial'
    }, {
      id: 4,
      name: 'Reparación',
      tipo: 'Pérdida Parcial'
    }, {
      id: 5,
      name: 'Daño material',
      tipo: 'Pérdida Total',
      archivos : [
        "Refactura o factura",
        "Facturas anteriores",
        "Identificación oficial vigente",
        "CURP",
        "RFC",
        "Baja de placas con comprobante de pago",
        "Tenencias de los últimos 5 años con sus comprobantes de pago",
        "Llaves y/o duplicado",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Póliza",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Solicitud de expedición de CFDI",
        "Acta constitutiva y poder notarial, si aplicará",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 6,
      name: 'Robo',
      tipo: 'Pérdida Total',
      archivos: [
        "Refactura o factura",
        "Facturas anteriores",
        "Identificación oficial vigente",
        "CURP",
        "RFC",
        "Baja de placas con comprobante de pago",
        "Tenencias de los últimos 5 años con sus comprobantes de pago",
        "Llaves y/o duplicado",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Póliza",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Solicitud de expedición de CFDI",
        "Acta de robo y acreditación de propiedad",
        "Aviso ante la PFP, si aplicará",
        "Acta constitutiva y poder notarial, si aplicará",
        "Otros (unidades legalizadas, …)",
        "Oficio de liberación de posesión",
        "Expediente Completo"
      ]
    }, {
      id: 7,
      name: 'Robo localizado',
      tipo: 'Pérdida Total',
      archivos:[
        "Refactura o factura",
        "Facturas anteriores",
        "Identificación oficial vigente",
        "CURP",
        "RFC",
        "Baja de placas con comprobante de pago",
        "Tenencias de los últimos 5 años con sus comprobantes de pago",
        "Llaves y/o duplicado",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Póliza",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Solicitud de expedición de CFDI",
        "Acta de robo y acreditación de propiedad",
        "Oficio de liberación en posesión",
        "Cancelación del reporte ante la PFP, si aplicará",
        "Acta constitutiva y poder notarial, si aplicará",
        "Otros (unidades legalizadas, …)",
        "Expediente Completo"
      ]
    }, {
      id: 9,
      name: 'Reembolso de gastos médicos',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura y xml",
        "Pase médico",
        "Informe medico",
        "Estudios",
        "Identificación oficial del beneficiario",
        "CURP",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 10,
      name: 'Reembolso de gastos funerarios',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura y xml",
        "Pase médico",
        "Acta de defunción",
        "Acta de nacimiento e identificación oficial del occiso",
        "Acreditación del beneficiario",
        "Identificación oficial del beneficiario",
        "CURP",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 11,
      name: 'Reembolso de grúa',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura y xml",
        "Factura de la unidad o tarjeta de circulación",
        "Identificación oficial",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Acta constitutiva y poder notarial, si aplicará",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 12,
      name: 'Reembolso de grúa por asistencia',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura y xml",
        "Factura de la unidad o tarjeta de circulación",
        "Identificación oficial",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Acta constitutiva y poder notarial, si aplicará",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 13,
      name: 'Reembolso de cristal',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura y xml",
        "Presupuesto",
        "Fotografías de la rotura e instalación",
        "Factura de la unidad o tarjeta de circulación",
        "Identificación oficial",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Acta constitutiva y poder notarial, si aplicará",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 14,
      name: 'Reembolso de deducible o daños a terceros',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Recibo de pago de deducible o al tercero",
        "Factura de la unidad o tarjeta de circulación",
        "Identificación oficial",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Acta constitutiva y poder notarial, si aplicará",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 15,
      name: 'Pago de daños',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Orden de admisión",
        "Factura de la unidad o tarjeta de circulación",
        "Identificación oficial",
        "RFC",
        "Comprobante de domicilio actual",
        "Estado de cuenta bancario",
        "Formato de información bancaria",
        "Formato de información del cliente",
        "Acta constitutiva y poder notarial, si aplicará",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }, {
      id: 16,
      name: 'Carta PT y montos',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Factura de origen del vehículo",
        "Póliza",
        "Otros",
        "Expediente Completo"
        // "Póliza y Otros"
      ]
    }, {
      id: 17,
      name: 'Carta rechazo',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Carta Rechazo",
        "Expediente Completo"
      ]
    }, {
      id: 18,
      name: 'Factura por deducible',
      tipo: 'Trámites Administrativos',
      archivos:[
        "Comprobante de pago",
        "RFC con domicilio fiscal",
        "Póliza",
        "Otros",
        "Expediente Completo"
      ]
    }
  ];
  subtypesv2: any = [
    {
      id: 1,
      name: 'Revisión de documentos',
      tipo: 'Pérdida Total'
    }, {
      id: 2,
      name: 'Indemnización',
      tipo: 'Pérdida Total'
    }, {
      id: 1,
      name: 'Revisión de documentos',
      tipo: 'Trámites Administrativos'
    }, {
      id: 2,
      name: 'Indemnización',
      tipo: 'Trámites Administrativos'
    }
  ];

  status: any = [
    {
      id: 0,
      name: 'Selecciona un Estado'
    },
    {
      id: 1,
      name: 'Pendiente'
    },
    {
      id: 2,
      name: 'En Trámite'
    }, {
      id: 3,
      name: 'Completada'
    }, {
      id: 4,
      name: 'Cancelada'
    }, {
      id: 5,
      name: 'Rechazada'
    }
    // , {
    //   id: 6,
    //   name: 'En espera'
    // }, {
    //   id: 7,
    //   name: 'Reproceso'
    // }, {
    //   id: 8,
    //   name: 'Inconformidad'
    // }
  ];
  showDate: boolean = false;
  fecha_siniestro;
  indemnizacion;
  deducible;
  coaseguro;
  reason;
  statusToSave;
  valorArchivos_nuevo = false
  form: FormGroup;
  formExistente: FormGroup;
  // listafiles: FormGroup;
  constructor(
    private _settings: SettingsService,
    private _promise: PromisesComponent,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getSinister();
    this.form = new FormGroup({
      subtipo_nivel_1 : new FormControl(0, [Validators.required, Validators.min(1)]),
      subtipo_nivel_2 : new FormControl(0, [Validators.required, Validators.min(1)]),
      status_siniester : new FormControl(),
      filetoupd : new FormControl(),
    });
    this.formExistente = new FormGroup({
      subtipo_nivel_1 : new FormControl(0, [Validators.required, Validators.min(1)]),
      subtipo_nivel_2 : new FormControl(0, [Validators.required, Validators.min(1)]),
      status_siniester : new FormControl(),
      filetoupd : new FormControl(),
    });
    // this.listafiles = new FormGroup({
    // });
  }
  // tslint:disable-next-line: typedef
  getSinister(){
    this.activateRoute.params.subscribe(params => {
      this._promise.getSinister(params['id'])
      .then(response => {
        this.siniester = response;
        let auxda=0;
        if (this.siniester.auto_siniester && this.siniester.auto_siniester[0].subtipo_siniestro_1_name == 'Reparación'){
          try{
            auxda = parseInt(response['antiguedad_dias_promesa']);
            this.siniester['antiguedad_dias_habiles_real_ok'] = parseInt(this.siniester['antiguedad_dias_habiles_real'])
            this.siniester['antiguedad_dias_habiles'] =  parseInt(response['antiguedad_dias_promesa']);
            if (this.siniester['antiguedad_dias_habiles'] < 0){
              this.siniester['antiguedad_dias_habiles'] = 0;
            }
            this.siniester['antiguedad_horas_habiles'] = auxda * 24.0;
            
          } catch(err){}
        } else {
          this.siniester['antiguedad_dias_habiles'] = parseInt(response['antiguedad_dias_habiles']);
          this.siniester['antiguedad_horas_habiles'] = parseFloat((response['antiguedad_dias_habiles']).toFixed(5)) * 24.0;
        }
        try{
          this.siniester['dias_horas_final'] = parseInt(response['dias_horas_final']);          
        }catch(e){
          console.log('----error-int dias--')
        }
        if (this.siniester['filesDelivery']){
          let jsonfiles = JSON.parse(this.siniester['filesDelivery'])
          this.formFilesSaved = jsonfiles;
        }
        try{
          this.autoSiniester = response['auto_siniester'][0];
          this.subtypes = this.subtypes.filter((st: { tipo: any; }) => st.tipo === this.autoSiniester.tipo_siniestro);
          this.subtypesv2 = this.subtypesv2.filter((sst: { tipo: any; }) => sst.tipo === this.autoSiniester.tipo_siniestro);
          this.subtypes.unshift({
            id: 0,
            name : 'Seleccione una opción',
            tipo : null
          });
          this.subtypesv2.unshift({
            id: 0,
            name : 'Seleccione una opción',
            tipo : null
          });
          this.form.get('subtipo_nivel_1').setValue(parseInt(this.autoSiniester['subtipo_siniestro_1']));
          this.form.get('subtipo_nivel_2').setValue(parseInt(this.autoSiniester['subtipo_siniestro_2']));
          this.loadFiles(parseInt(this.autoSiniester['subtipo_siniestro_1']));
          if(this.siniester['promise_date']){
            let dia = this.siniester['promise_date'].substr(8, 2);
            let mes = this.siniester['promise_date'].substr(5, 2);
            let anio = this.siniester['promise_date'].substr(0, 4);
            
            this.fecha_siniestro = anio + '-' + mes + '-' + dia;
          }
        } catch (err){
          this.autoSiniester = {};
        }
        this.getPolicy();
      }).catch(error => {
        if(error['status'] == 403){
          this.logout();
        }
      });
    });
  }
  
  getPolicy(){
    this._promise.getPolicy(this.siniester['poliza'].url)
    .then(response => {
      this.policy = response;
      this.getProvider();
      this.getContractor();
      this.getSubramo();
    }).catch(error => {
    });
  }
  
  getProvider(){
    this._promise.getProvider(this.policy['aseguradora'])
    .then(response => {
      this.policy['aseguradora'] = response;
    }).catch(error => {
    });
  }
  
  getContractor(){
    this._promise.getProvider(this.policy['contractor'])
    .then(response => {
      this.policy['contractor'] = response;
    }).catch(error => {
    });
  }
  
  getSubramo(){
    this._promise.getProvider(this.policy['subramo'])
    .then(response => {
      this.policy['subramo'] = response;
    }).catch(error => {
    });
  }

  deleteFile(file){
    Swal.fire({
      title: 'Eliminar Archivo',
      text: 'Se eliminara el archivo de SAAM. ¿Estás seguro?',
      showCancelButton: true,
      confirmButtonText: '¡Si!',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        // this._router.navigate(['/templates', 'nuevo']);
      }
    });
  }

  onFileChange(nombre, event, index){
    let a = {}
    a[nombre] = nombre
    let reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        (this.form.get(nombre) as FormGroup).get('file').setValue(file);
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
    if(event.target.files[0].name){
      this.event[index] = event.target.files[0].name;
    }
  }
  onFileChangeUpd(nombre, eventx, index,archivoUPD){
    console.log('-d===',archivoUPD,'index',index,nombre,eventx)
    let a = {}
    a[nombre] = nombre
    let reader = new FileReader();
 
    if(eventx.target.files && eventx.target.files.length) {
      const file = eventx.target.files[0];
      reader.readAsDataURL(file);
      console.log('this.formExistente',this.formExistente,nombre)
      reader.onload = () => {
        (this.formExistente.get(nombre) as FormGroup).get('file').setValue(file);
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
    if(eventx.target.files[0].name){
      this.eventx[index] = eventx.target.files[0].name;
    }
  }
  onFileChangeDelivery(i, event,arch,value){
    arch.delivery = event.target.checked
    this._promise.patchUrl(arch.url, {delivery:event.target.checked,checked:event.target.checked}).then(() => {
      this.listafiles.map(sf => {
        if (sf.id == arch.id){
          sf.delivery = arch.delivery;
          return sf;
        }
        // window.location.reload();
      })
      if (arch.delivery){
        this.toastr.success('Archivo Entregado');
      } else {
        this.toastr.success('Archivo No entregado');
      }
    })
  }
  saveUpdFiles(){
    this.uploadFilesV2();
  }
  uploadFilesV2(){  
    let totalfiles = this.listafiles ? this.listafiles.length : 0
    let countfiles = 0
    Object.values(this.listafiles).forEach(valor => {
      Object.keys(this.formExistente.controls).forEach(key => {
        const formControl = (this.formExistente.controls[key] as FormGroup).get('file');
        console.log('formControl---------',formControl)
        countfiles = countfiles+1
        if (formControl && (this.formExistente.controls[key] as FormGroup).get('file').value ){
          console.log('nombre', key,totalfiles,countfiles);
          console.log('file', (this.formExistente.controls[key] as FormGroup).get('file').value);
          if(valor){     
            if(valor && valor['nombre']){
              if(key ==valor['nombre']){          
                let formData:FormData = new FormData();
                formData.append('arch', (this.formExistente.controls[key] as FormGroup).get('file').value);
                formData.append('nombre', key);
                formData.append('checked', 'true');
                formData.append('delivery', 'true');
                formData.append('owner', this.siniester['url']);
                let url_files = valor['url']
                this._promise.uploadFileUPD(url_files, formData).then(response => {
                  // this.formExistenteFiles.push(response);          
                  try{
                    this.siniester.siniestro_files.forEach(archivoe => {
                      if (this.listafiles.includes(archivoe.nombre)){
                        const index = this.listafiles.indexOf(archivoe.nombre);
                        if (index !== -1){
                          this.listafiles.splice(index, 1);
                        }
                      }
                    });
                  }
                  catch (err){console.log('eror',err)}
                  (this.formExistente.controls[key] as FormGroup).get('file').setValue(null);
                  this.toastr.success('El archivo "' + key + '" se ha cargado con éxito', 'Carga exitosa' );
                  if(this.listafiles.length == this.listaFilesDelivery.length){
                    let payload_1 = {
                      "filesDelivery": JSON.stringify(this.listaFilesDelivery)
                    }
                    this._promise.updateMainSiniester(this.siniester.id, payload_1).then(response => {        
                        // window.location.reload();
                    });            
                  }
                });
              }
            }
          }
          // window.location.reload();
          // let formData:FormData = new FormData();
          // formData.append('arch', (this.form.controls[key] as FormGroup).get('file').value);
          // formData.append('nombre', key);
          // formData.append('checked', 'true');
          // formData.append('delivery', 'true');
          // formData.append('owner', this.siniester['id']);
          // this._promise.uploadFileUPD(url_files, formData).then(response => {
          //   this.siniester.siniestro_files.push(response);          
          //   countfiles = countfiles+1
          //   try{
          //     this.siniester.siniestro_files.forEach(archivoe => {
          //       if (this.listafiles.includes(archivoe.nombre)){
          //         const index = this.listafiles.indexOf(archivoe.nombre);
          //         if (index !== -1){
          //           this.listafiles.splice(index, 1);
          //         }
          //       }
          //     });
          //   }
          //   catch (err){console.log('eror',err)}
          //   (this.form.controls[key] as FormGroup).get('file').setValue(null);
          //   console.log('--------**',this.listafiles)
          //   this.toastr.success('El archivo "' + key + '" se ha cargado con éxito', 'Carga exitosa' );
          //   console.log('-fileeee',this.listaFilesDelivery)
          //   if(this.listafiles.length == this.listaFilesDelivery.length){
          //     console.log('-xxxxxxxxxxxx',this.listaFilesDelivery)
          //     let payload_1 = {
          //       "filesDelivery": JSON.stringify(this.listaFilesDelivery)
          //     }
          //     this._promise.updateMainSiniester(this.siniester.id, payload_1).then(response => {        
                  // window.location.reload();
          //     });            
          //   }
          //   if(totalfiles == countfiles){
              // window.location.reload();
          //   }
          // });
        }        
        console.log('--dejarrel233',totalfiles,countfiles)
        if(totalfiles == countfiles){
          this.toastr.success('Archivos guardados, recargue pantalla' );
          // window.location.reload();
        }
      });
    });
  }

  save(){
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      return
    }
    let payload = {
      subtipo_siniestro_1: this.form.get('subtipo_nivel_1').value,
      subtipo_siniestro_2: this.form.get('subtipo_nivel_2').value
    }
    if(this.statusToSave){
      let payload3 = {
        "status": this.statusToSave
      }
      if(this.statusToSave ==3 || this.statusToSave =='3'){   
        let payl = {     
          'indemnizacion':this.indemnizacion ? (parseFloat(this.indemnizacion)).toFixed(2) : 0,
          'coaseguro': this.coaseguro ? (parseFloat(this.coaseguro)).toFixed(2) : 0,
          'deducible': this.deducible ? (parseFloat(this.deducible)).toFixed(2) : 0
        }
        this._promise.updateSiniester(this.autoSiniester.url, payl).then(resp => {
          console.log('-dooooo',resp)
        })
      }
      if((this.statusToSave ==4 || this.statusToSave =='4') || (this.statusToSave ==5 || this.statusToSave =='5')){   
        payload3['reason']=this.reason ? this.reason : ''
      }
      this._promise.updateSiniester(this.siniester.url, payload3).then(response => { 
        let statusCh=this.statusToSave
        if(this.statusToSave ==1 || this.statusToSave =='1'){
          statusCh='Pendiente.'
        }else if(this.statusToSave==2 || this.statusToSave =='2'){
          statusCh='En Trámite.'
        } else if(this.statusToSave==3 || this.statusToSave =='3'){
          statusCh='Completada.'
        } else if(this.statusToSave==4 || this.statusToSave == '4'){
          statusCh='Cancelada.'
        } else if(this.statusToSave==5 || this.statusToSave =='5'){
          statusCh='Rechazada.'
        } else{
          statusCh=''
        }
        let payload_4 = {
          'model': 5,
          'event': "PATCH",
          'associated_id': this.siniester['id'],
          'identifier': "actualizo el siniestro a estatus "+statusCh
        }       
        this._promise.sendLog(payload_4).then(response => { 
        });
        // window.location.reload();   
      });
    }
    if(this.comment){
      //save comment bitacora siniestro
      let datacomment = {
        'model':5,
        'id_model': this.siniester.id,
        'content': this.comment,
        'parent': null,
        'create_task': false
      };
      this._promise.createComment(datacomment).then(response => {
          this.comment = ''
      });
    }
    this._promise.updateSiniester(this.autoSiniester.url, payload).then(response => {
      console.log('respuesta patch',response)
      if(response['id']){
        Swal.fire('¡Listo!', 'Siniestro actualizado.', 'success');
        // if(this.autoSiniester['tipo_siniestro'] == "Pérdida Parcial" && this.autoSiniester.subtipo_siniestro_1_name != 'Reparación' ){
        //   let now = new Date();
          let payload2 = {
            "filesDelivery": JSON.stringify(this.listaFilesDelivery)
          }
          this._promise.updateMainSiniester(this.siniester.id, payload2).then(response => {        
              // window.location.reload();
          });
        // window.location.reload();
        // }
      }
      if(this.siniester.siniestro_files.length ==0 || this.valorArchivos_nuevo){
        console.log('-crear file',this.valorArchivos_nuevo)
        this.uploadFiles();
      }else{
        console.log('-upd files',this.valorArchivos_nuevo)
        this.uploadFilesV2();
      }
    })
    if(this.form.get('subtipo_nivel_2').value == 2){
      let now = new Date();
      // let payload = {
      //   "fecha_archivos_cargados": now,
      //   "a": 0
      // }
      // this._promise.updateMainSiniester(this.siniester.id, payload).then(response => {
        Swal.fire('Confirmado', 'Guardado confirmado', 'success');
        this.getSinister();
        this.form = new FormGroup({
          subtipo_nivel_1 : new FormControl(0, [Validators.required, Validators.min(1)]),
          subtipo_nivel_2 : new FormControl(0, [Validators.required, Validators.min(1)]),
        });
      // });
    }

    if(this.autoSiniester['tipo_siniestro'] == "Pérdida Parcial"){
      console.log('--------------',this.fecha_siniestro)      
      if(!this.fecha_siniestro){
        Swal.fire('Error', 'Seleciona una fecha promesa.', 'success');
        return;
      }
      let dia = String(this.fecha_siniestro.substr(8,2));
      let mes = String(this.fecha_siniestro.substr(5,2));
      let anio = String(this.fecha_siniestro.substr(0,4));
      dia = dia.length == 1 ? '0' + dia : dia;
      mes = mes.length == 1 ? '0' + mes : mes;
      let dat = new Date(anio + '-' + mes + '-' + dia).toISOString();
      // let nuevadate = this.fecha_siniestro.setTime(this.fecha_siniestro.getTime() + 6 * 60 * 60 * 1000)
      let currentTime =new Date(dat).getTime();
      let updatedTIme = new Date(currentTime + 6 * 60 * 60 * 1000)
      let payload_x = {
        // "promise_date": dat,
        "promise_date": updatedTIme,
      }
      this._promise.patchUrl(this.siniester.url, payload_x).then(response => {})
        
      // });
      // let now = new Date();
      // let payload2 = {
      //   "fecha_archivos_cargados": now,
      // }
      // this._promise.updateMainSiniester(this.siniester.id, payload2).then(response => {        
          // window.location.reload();
      // });
    }
  }

  loadFiles(item){ 
    if(item ==18 || item ==16 || item ==17){
      this.subtypesv2= [{
          id: 1,
          name: 'Revisión de documentos',
          tipo: 'Trámites Administrativos'
        }
      ]; 
    } else{
      this.subtypesv2= [
        {
          id: 1,
          name: 'Revisión de documentos',
          tipo: 'Pérdida Total'
        }, {
          id: 2,
          name: 'Indemnización',
          tipo: 'Pérdida Total'
        }, {
          id: 1,
          name: 'Revisión de documentos',
          tipo: 'Trámites Administrativos'
        }, {
          id: 2,
          name: 'Indemnización',
          tipo: 'Trámites Administrativos'
        }
      ];
    }  
    this.subtypesv2 = this.subtypesv2.filter((sst: { tipo: any; }) => sst.tipo === this.autoSiniester.tipo_siniestro);
    this.subtypes.unshift({
      id: 0,
      name : 'Seleccione una opción',
      tipo : null
    });
    this.subtypesv2.unshift({
      id: 0,
      name : 'Seleccione una opción',
      tipo : null
    });
    if(item == 4){
      this.showDate = true;
    }else{
      this.showDate = false;
    }
    this.subtypes.forEach(st => {
      if (st['id'] == item){
        try{
          this.archivos = st['archivos']
          console.log('archivos***',this.archivos)
          this.archivos.map(archivo => {
            this.form.addControl(archivo,new FormGroup({
              file: new FormControl()
            }))
          })
          try{
            let existeff = false
            this.siniester.siniestro_files.forEach(archivoe => {
              if(archivoe.arch == null){               
                this.listafiles.push(archivoe)
              }else{
                this.formFiles.push(archivoe)
              }
            });
            this.listafiles.map(archivo => {
              this.formExistente.addControl(archivo.nombre,new FormGroup({
                file: new FormControl()
              }))
            })
            // if(this.archivos.length != this.siniester.siniestro_files.length){
            //   console.log('-faltan archivos')
            //   this.archivos.forEach(archivo_in_archivos => {
            //     if(archivo_in_archivos.arch == null){               
            //       this.listafiles.push(archivo_in_archivos)
            //     }else{
            //       this.formFiles.push(archivo_in_archivos)
            //     }
            //   });
            // }
            if(this.siniester.siniestro_files.length !=0){
              if(((this.listafiles.length +this.formFiles.length) == this.siniester.siniestro_files.length) && (this.listafiles.length +this.formFiles.length) == this.archivos.length){
                this.archivos = []
                console.log('oo--',this.archivos)
              }else{
                this.valorArchivos_nuevo = true
                console.log('thsssssssss',this.valorArchivos_nuevo)
              }
            }
            try{
              this.siniester.siniestro_files.forEach(archivoe => {
                if (this.archivos.includes(archivoe.nombre)){
                  const index = this.archivos.indexOf(archivoe.nombre);
                  if (index !== -1) this.archivos.splice(index, 1);
                }
              });
            }
            catch (err){}
            console.log('de',this.listafiles.length,this.formFiles.length,this.siniester.siniestro_files.length,this.archivos)
          }
          catch (err){}
        } catch (err){};
      }
    });
    // this.formFiles = this.archivos;
  }

  statusChange(item){ 
    this.statusToSave = ''
    if (item !=0 || item !='0'){
      this.statusToSave = item
    }
  }
  uploadFiles(){  
    let totalfiles = this.archivos ? this.archivos.length : 0
    let countfiles = 0
    Object.keys(this.form.controls).forEach(key => {
      const formControl = (this.form.controls[key] as FormGroup).get('file');
      if (formControl && (this.form.controls[key] as FormGroup).get('file').value ){
        let formData:FormData = new FormData();
        formData.append('arch', (this.form.controls[key] as FormGroup).get('file').value);
        formData.append('nombre', key);
        formData.append('checked', 'true');
        formData.append('delivery', 'true');
        formData.append('owner', this.siniester['id']);
        this._promise.uploadFile(this.siniester['id'], formData).then(response => {
          countfiles = countfiles+1
          this.siniester.siniestro_files.push(response);          
          try{
            this.siniester.siniestro_files.forEach(archivoe => {
              if (this.archivos.includes(archivoe.nombre)){
                const index = this.archivos.indexOf(archivoe.nombre);
                if (index !== -1){
                  this.archivos.splice(index, 1);
                }
              }
            });
          }
          catch (err){console.log('eror',err)}
        });
      }else{
        this.listaFilesDelivery.push({'file': key,'delivery': false});  
        let existeFile = false
        // --------------------------
        if (formControl && (this.form.controls[key] as FormGroup).get('file').value ==null){      
          this.siniester.siniestro_files.forEach(fil => {
            if(key ==fil.nombre){
              existeFile = true
            }
          })
          if(existeFile == false){
            let payl = {'arch': null,'nombre':key,'checked': false,'delivery':false,'owner': this.siniester['id']}
            this._promise.uploadFileOnlyNull(this.siniester['id'], payl).then(response => {
              countfiles = countfiles+1
              this.siniester.siniestro_files.push(response); 
              try{
                this.siniester.siniestro_files.forEach(archivoe => {
                  if (this.archivos.includes(archivoe.nombre)){
                    const index = this.archivos.indexOf(archivoe.nombre);
                    if (index !== -1){
                      this.archivos.splice(index, 1);
                    }
                  }
                });
              }
              catch (err){console.log('eror',err)}
            // ---------------------------
            });      
          }
        }
      }
    });
    console.log('rtotalfilescountfiles',totalfiles,countfiles)
    if(this.listafiles.length >0){
      console.log('revisar si archivos upd',this.listafiles)
      this.uploadFilesV2()
    }
    if(totalfiles == countfiles){
      this.toastr.success('Debe recargar la Página para ver cambios' );
      console.log('11--totalfilescountfiles',totalfiles,countfiles)
      // window.location.reload();
    }
  }

  validateFile(file){
    console.log('-xxxxxxxxx',file)
    this._promise.patchUrl(file.url, {checked : file.checked}).then(() => {
      this.siniester.siniestro_files.map(sf => {
        if (sf.id == file.id){
          sf.checked = file.checked;
        }
        return sf;
      })
      if (file.checked){
        this.toastr.success('Archivo validado correctamente');
      } else {
        this.toastr.success('Archivo invalidado correctamente');
      }
    })
  }
  deliveryFile(file){
    this._promise.patchUrl(file.url, {delivery : file.delivery,checked : file.delivery}).then(() => {
      this.siniester.siniestro_files.map(sf => {
        if (sf.id == file.id){
          sf.delivery = file.delivery;
          sf.checked = file.checked;
        }
        return sf;
      })
      if (file.delivery){
        this.toastr.success('Archivo entregado \n'+file.nombre);
      } else {
        this.toastr.success('Archivo No entregado \n'+file.nombre);
      }
    })
  }
  get v1NoValido() {
    return this.form.get('subtipo_nivel_1').invalid && this.form.get('subtipo_nivel_1').touched;
  }
  get sttsNoValido() {
    return this.form.get('status_siniester').invalid && this.form.get('status_siniester').touched;
  }
  get v2NoValido() {
    return this.form.get('subtipo_nivel_2').invalid && this.form.get('subtipo_nivel_2').touched;
  }
  validateFilesv2(){
      if(this.autoSiniester['tipo_siniestro'] == 'Pérdida Parcial'){
        this.showDate = true;
      }
      Swal.fire({
        title: 'Iniciar Semáforo',
        text: "A partir de ahora comenzará el semáforo",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, validar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          var now = new Date();
          // now.setHours(now.getHours()-5);
          // now.setMinutes(now.getMinutes()-59);
          try{
            this.siniester.siniestro_files.forEach(archivo => {
              if (!archivo.checked){  this._promise.patchUrl(archivo.url, {checked : true}).then(()=>{
                  archivo.checked = true;
                });}
            });
          }
          catch (err){}
          if(this.autoSiniester['tipo_siniestro'] == "Pérdida Parcial"){
            // if(!this.fecha_siniestro){
            //   Swal.fire('Error', 'Seleciona una fecha promesa.', 'success');
            //   return;
            // }
            // let dia = String(this.fecha_siniestro.substr(8,2));
            // let mes = String(this.fecha_siniestro.substr(5,2));
            // let anio = String(this.fecha_siniestro.substr(0,4));
            // dia = dia.length == 1 ? '0' + dia : dia;
            // mes = mes.length == 1 ? '0' + mes : mes;
            // let dat = new Date(anio + '-' + mes + '-' + dia).toISOString();
            // // let nuevadate = this.fecha_siniestro.setTime(this.fecha_siniestro.getTime() + 6 * 60 * 60 * 1000)
            // let currentTime =new Date(dat).getTime();
            // let updatedTIme = new Date(currentTime + 6 * 60 * 60 * 1000)
            let payload = {
              // "promise_date": dat,
              // "promise_date": updatedTIme,
              "promise_date":new Date()
            }
            this._promise.patchUrl(this.siniester.url, payload).then(response => {
              
            });
            let now = new Date();
            let payload2 = {
              "fecha_archivos_cargados": now,
            }
            this._promise.updateMainSiniester(this.siniester.id, payload2).then(response => {        
                window.location.reload();
            });
          }else{
            let payload = {
              "fecha_archivos_cargados": now,
              "a": 0
            }
            this._promise.updateMainSiniester(this.siniester.id, payload).then(response => {
              Swal.fire(
                'Confirmado',
                'El semáforo se ha iniciado',
                'success'
              )
              this.getSinister();
              window.location.reload();
            });
          }
        }
      })
  }
  validateFiles(){
    if(this.siniester.siniestro_files.length == 0){
      Swal.fire('Error', 'No hay archivos para validar', 'error');
    }else{
      Swal.fire({
        title: 'Validar archivos de siniestro',
        text: "Al hacer esto se validarán todos los archivos cargados y a partir de ahora comenzará el semáforo",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, validar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          var now = new Date();
          // now.setHours(now.getHours()-5);
          // now.setMinutes(now.getMinutes()-59);
          try{
            this.siniester.siniestro_files.forEach(archivo => {
              if (!archivo.checked){  this._promise.patchUrl(archivo.url, {checked : true}).then(()=>{
                  archivo.checked = true;
                  archivo.delivery = true;
                });}
            });
          }
          catch (err){}
          let payload = {
            "fecha_archivos_cargados": now,
            "a": 0
          }
          this._promise.updateMainSiniester(this.siniester.id, payload).then(response => {
            Swal.fire(
              'Confirmado',
              'Los archivos se han confirmado',
              'success'
            )
            this.getSinister();
            window.location.reload();
          });
        }
      })
    }
  }
  
  logout(){
    this._settings.borarAjustes();
    this.router.navigate(['/login']);
  }

}
