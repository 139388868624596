import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [
    {
      title: 'Siniestros',
      icon: 'mdi mdi-account-card-details',
      submenu: [
        {title: 'Ver Siniestros', url: 'siniestros'}
      ]
    }
  ];
  constructor() { }
}
