<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form class="floating-labels m-t-40">
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.first_name" name="name" id="input8" disabled><span class="bar"></span>
                        <label for="input8">{{ 'profile.first_name'  }}</label>
                    </div>
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.last_name" name="last_name" id="input8" disabled><span class="bar"></span>
                        <label for="input8">{{ 'profile.last_name'  }}</label>
                    </div>
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.second_last_name" name="second_last_name" id="input8" disabled><span class="bar"></span>
                        <label for="input8">{{ 'profile.second_last_name'  }}</label>
                    </div>
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.rfc" name="rfc" id="input8" disabled><span class="bar" disabled></span>
                        <label for="input8">{{ 'profile.rfc'  }}</label>
                    </div>
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.phone" name="phone" id="input8" disabled><span class="bar"></span>
                        <label for="input8">{{ 'profile.phone'  }}</label>
                    </div>
                    <div class="form-group m-b-40">
                        <input type="text" class="form-control input-sm" [(ngModel)]="user.email" name="email" id="input8" disabled><span class="bar"></span>
                        <label for="input8">{{ 'profile.email'  }}</label>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>