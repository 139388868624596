import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PoliciesComponent } from './policies/policies.component';
import { DetailsComponent } from './details/details.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { RxjsComponent } from './rxjs/rxjs.component';

const pagesRoutes: Routes = [
    { 
        path: '',
        component: PagesComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent, data: {title: 'Dasboard'}},
            { path: 'perfil', component: ProfileComponent, data: {title: 'Perfil'}},
            { path: 'siniestros', component: PoliciesComponent, data: {title: 'Siniestros'} },
            { path: 'siniestro/:id', component: DetailsComponent, data: {title: 'Siniestros'} },
            { path: 'rxjs', component: RxjsComponent, data: {title: 'RxJs'} },
            { path: 'account-settings', component: AccountSettingsComponent, data: {title: 'Configuración'} },
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
        ]
    },
]

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );