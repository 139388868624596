<script src="bower_components/ladda/js/spin.js"></script>
<script src="bower_components/ladda/js/ladda.js"></script>
<script src="bower_components/angular-ladda/dist/angular-ladda.min.js"></script>
<div class="row">
    <div class="col-12">
        <h4 class="d-inline"><i class="mdi mdi-car"></i> Automóviles ({{ siniesters_car}})</h4>
        <hr>
    </div>
    <div class="col-lg-12 form-group">
        <div class="card">
            <div class="card-body">
                <div class="col-12 form-group">
                    <div class="col-12 col-sm-6">
                        <label class="texto-cotizador">Buscador</label>
                        <input class="form-control" type="text" [(ngModel)]="numberSiniester" placeholder="Número de siniestro, serie, asegurado, folio, número póliza, ...">
                        <button class="pull-right" (click)="searchByNumber(numberSiniester)">Consultar</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="col-12 form-group">
                        <button class="btn" *ngIf="previous" (click)="previous_page()"><i class="fa  fa-chevron-left"></i></button>
                        <button class="btn" *ngIf="next" (click)="next_page()"><i class="fa  fa-chevron-right"></i></button>
                        <button class="pull-right" (click)="reload()">Recargar</button>
                        <button class="pull-right" (click)="generarReporte()" *ngIf="!loading">Generar reporte</button><label *ngIf="loading" class="pull-right">El reporte se esta generando</label>
                    </div>
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th class="text-themecolor">No. Siniestro</th>
                                <th class="text-themecolor">Tipo de Siniestro</th>
                                <th class="text-themecolor">Estatus</th>
                                <th class="text-themecolor">Fecha Ingreso</th>
                                <th class="text-themecolor">No. Póliza</th>
                                <th class="text-themecolor">Contratante</th>
                                <th class="text-themecolor">Aseguradora</th>
                                <th class="text-themecolor">Subtipo Nivel 1</th>
                                <th class="text-themecolor">Creado por</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of siniesters" (click)="goToNavigation(item)">
                                <td>{{ item.numero_siniestro }}</td>
                                <td>{{ item['auto_siniester'][0]['tipo_siniestro'] }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.fecha_ingreso | date: 'dd/MM/yyyy' }}</td>
                                <td>{{ item.poliza.poliza_number }}</td>
                                <td>{{ item.poliza.contractor }}</td>
                                <td>{{ item.poliza.aseguradora }}</td>
                                <td>{{ item['auto_siniester'][0]['subtipo_siniestro_1_name'] }}</td>
                                <td>{{ item.owner }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-12">
        <h4 class="d-inline"><i class="mdi mdi-medical-bag"></i> Gastos Médicos ({{ siniesters_medical.length }})</h4>
        <hr>
    </div>
    <div class="col-12">
        <h4 class="d-inline"><i class="mdi mdi-heart"></i> Vida ({{ siniesters_life.length }})</h4>
        <hr>
    </div> -->
</div>
