import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/service.index';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styles: []
})
export class AccountSettingsComponent implements OnInit {

  constructor(public _settings: SettingsService) { }

  ngOnInit() {
    this.colocarCambio();
  }

  cambiarColor(tema: string, link: any){
    this.aplicarCambio(link);
    this._settings.aplicarTema(tema);
  };

  aplicarCambio(link: any){
    let selectores: any = document.getElementsByClassName('selector');

    for(let ref of selectores){
      ref.classList.remove('working');
    }

    link.classList.add('working');
  };

  colocarCambio(){
    let selectores: any = document.getElementsByClassName('selector');

    for(let ref of selectores){
      if(ref.getAttribute('data-theme') === this._settings.settings.tema){
        ref.classList.add('working');
        break;
      }
    }

  };
}
