<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect" href="#" aria-expanded="false">
                        <!-- <img src="../assets/images/users/man.png" alt="user" /> -->
                        <i class=" mdi mdi-account-circle"></i>
                        <span class="hide-menu">{{ _settings.settings.first_name }} {{ _settings.settings.last_name }}</span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li ><a routerLink="/login" (click)="logout()"><i class="ti-power-off"></i> Cerrar sesión</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>
                <li *ngFor="let menu of _sidebar.menu">
                    <a class="has-arrow waves-effect waves-dark" aria-expanded="false" routerLinkActive="active">
                        <i [class]="menu.icon"></i>
                        <span class="hide-menu">{{ menu.title }} 
                            <span *ngIf="menu.submenu.length > 0" class="label label-rouded label-themecolor pull-right">{{ menu.submenu.length }}</span>
                        </span>
                    </a>
                    <ul *ngIf="menu.submenu.length > 0" aria-expanded="false" class="collapse">
                        <li *ngFor="let submenu of menu.submenu"><a routerLinkActive="active" [routerLink]="[submenu.url]">{{ submenu.title }}</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
        <div style="position: absolute; bottom: 20px; left: -20px;">
            <!-- <img src="../../../assets/images/logo_mbox.png" alt=""> -->
            <img src="../../../assets/images/ancora_logo.jpg" alt="" class="dark-logo" style="margin: 0 10px;width: 250px;" />
            <label style="margin: 0 0 0 40px;">2021</label>
        </div>
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->